export const API = {
  // General Links

  // BASEURL: "https://apidev.3bfab.com",
  // BASEURL: "https://novahub.eu.ngrok.io",
  // BASEURL: "http://serhat.eu.ngrok.io",
  BASEURL: "https://api.3bfab.com",
  // BASEURL: "http://192.168.100.3:8000/",

  TOKEN: "/o/token/",
  REVOKE_TOKEN: "/o/revoke-token/",
  ME: "/api/frontend/me/",
  UPDATE_INFO: "/api/frontend/me/invoice_detail/",
  CHANGEPASS: "/api/frontend/me/change_password/",
  FORGOTPASS: "/api/frontend/reset_password/",
  VALIDATEPASSTOKEN: "/api/frontend/reset_password/validate_token/",
  CONFIRMPASS: "/api/frontend/reset_password/confirm/",
  REGISTER: "/api/accounts/register/",
  EMAILACTIVATION: "/api/accounts/activate/",

  // Control Panel

  MACHINES: "/api/frontend/machines/",
  PRINTLOGS: "/api/frontend/print_logs/",
  PRINTLOGSFILTER: "/api/frontend/print_logs-filter/",

  // Design Market

  PRODUCTS: "/api/frontend/design-market/products/",
  PRODUCT_FILTER: "/api/frontend/design-market/products-filter/",
  ORDERS: "/api/frontend/design-market/orders/",
  PLATES: "/api/frontend/design-market/plates/",
  CREDIT_DESIGN: "/api/frontend/design-market/credit-packets/",
  CREDIT_DESIGN_HISTORY: "/api/frontend/design-market/credit-history/",

  // Dental
  DENTALORDERS: "/api/frontend/dental-platform/orders/",
  DENTALLABS: "/api/frontend/dental-platform/laboratories/",
  CREDIT_DENTAL: "/api/frontend/dental-platform/credit-packets/",
  CREDIT_DENTAL_HISTORY: "/api/frontend/dental-platform/credit-history/",
  DENTAL_FILES: "/api/frontend/dental-platform/files/",
  DENTAL_TREATMENTS: "/api/frontend/dental-platform/treatments/",

  //Dental Provider
  DENTAL_PROVIDER_ORDERS: "/api/frontend/dental-platform/provider/orders/",
  DENTAL_PROVIDER_ORDERS_REPORT:
    "/api/frontend/dental-platform/provider/orders/report/",

  // Nokta Market Provider
  NOKTA_MARKET_PROVIDER_CATEGORIES:
    "/api/frontend/nokta-market/provider/categories/",
  NOKTA_MARKET_PROVIDER_COLLECTIONS:
    "/api/frontend/nokta-market/provider/collections/",
  NOKTA_MARKET_PROVIDER_PRODUCT_GROUP:
    "/api/frontend/nokta-market/provider/product-groups/",
  NOKTA_MARKET_PROVIDER_PRODUCT_GROUP_IMAGES:
    "/api/frontend/nokta-market/provider/product-groups-images/",
  NOKTA_MARKET_PROVIDER_PRODUCT_SUB_GROUP:
    "/api/frontend/nokta-market/provider/product-sub-groups/",
  NOKTA_MARKET_PROVIDER_PRODUCT:
    "/api/frontend/nokta-market/provider/products/",
  NOKTA_MARKET_PROVIDER_CUSTOMER:
    "/api/frontend/nokta-market/provider/customers/",

  // Nokta Market
  NOKTA_MARKET_CATEGORIES: "/api/frontend/nokta-market/customer/categories/",
  NOKTA_MARKET_COLLECTIONS: "/api/frontend/nokta-market/customer/collection/",
  NOKTA_MARKET_PRODUCT_GROUP:
    "/api/frontend/nokta-market/customer/product_group/",
  NOKTA_MARKET_ORDERS: "/api/frontend/nokta-market/customer/orders/",
  NOKTA_MARKET_GOLD_TYPE: "/api/frontend/me/company/nokta_market/",
  NOKTA_MARKET_CREDIT: "/api/frontend/nokta-market/customer/credit-request/",
  NOKTA_MARKET_CREDIT_HISTORY:
    "/api/frontend/nokta-market/customer/credit-history/"
};

export default API;
