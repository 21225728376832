<template>
  <div>
    <vs-popup
      fullscreen
      :title="$t('Plate_Details')"
      :active.sync="popupActive"
    >
      <vs-row>
        <div class="popupFullScreen">
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            :vs-lg="popupSizeCount"
            vs-xs="12"
            :key="product.id"
            v-for="product in plateDetail"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <img
                class="productImage"
                :src="product.order_item.product.thumbnail"
                alt=""
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <div>
                <vs-row vs-type="flex" vs-justify="center">
                  <b class="popupInfo">
                    {{ product.order_item.product.title }}</b
                  >
                </vs-row>
                <vs-row vs-type="flex" vs-justify="flex-start">
                  <b class="popupInfo">
                    {{ $t("Size") }}:
                    {{ controlSize(product.order_item.size) }}</b
                  >
                </vs-row>
                <vs-row vs-type="flex" vs-justify="flex-start">
                  <b class="popupInfo">
                    {{ $t("Resin") }} {{ $t("Gr") }}:
                    {{ product.order_item.product.resin_gr }}</b
                  >
                </vs-row>
                <vs-row vs-type="flex" vs-justify="flex-start">
                  <b class="popupInfo"
                    >{{ $t("Quantity") }}:
                    {{ product.quantity_of_order_item }}</b
                  >
                </vs-row>
              </div>
            </vs-col>
          </vs-col>
        </div>
      </vs-row>
    </vs-popup>
    <div class="flex">
      <div class="valueBackground w-2/12">
        <p>{{ plate.order }}</p>
      </div>
      <div class="valueBackground w-2/12">
        <p>{{ plate.id }}</p>
      </div>
      <div class="valueBackground w-2/12">
        <p>{{ plate.remaining }}</p>
      </div>
      <div class="valueBackground w-6/12">
        <vs-button
          class="mr-2 mb-2"
          v-if="plate.order"
          @click="
            popupActive = true;
            openPopupForDetails();
          "
          color="primary"
          type="border"
        >
          {{ $t("Info") }}
        </vs-button>
        <vs-button
          :color="downloadCancelButtonColor"
          v-if="plate.can_download"
          @click="downloadPlate()"
        >
          {{ downloadCancelButtonTitle }}
        </vs-button>
        <vs-progress
          v-if="filePersent && filePersent != 100"
          :height="12"
          :percent="filePersent"
        ></vs-progress>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";
import axios from "axios";

export default {
  props: ["plate"],
  data() {
    return {
      popupActive: false,
      plateDetail: [],
      filePersent: 0,
      downloadCancelButtonTitle: this.$t("Download"),
      downloadCancelButtonColor: "primary",
      request: axios.CancelToken.source(),
    };
  },
  computed: {
    popupSizeCount() {
      if (this.plateDetail.length > 1) {
        return 6;
      } else {
        return 12;
      }
    },
  },
  destroyed() {
    this.requestCancel();
  },
  methods: {
    requestCancel() {
      this.request.cancel("Cancel");
      this.downloadCancelButtonTitle = this.$t("Download");
      this.downloadCancelButtonColor = "primary";
    },
    downloadPlate() {
      if (this.downloadCancelButtonTitle == this.$t("Cancel")) {
        this.requestCancel();
        return;
      }
      this.downloadCancelButtonTitle = this.$t("Cancel");
      this.downloadCancelButtonColor = "danger";
      Api.get(
        API.BASEURL + API.PLATES + this.plate.id + "/download/",
        this.handleDownloadPlate
      );
    },
    handleDownloadPlate(status, data) {
      if (status == 200) {
        if (data.model_file_url != "") {
          this.request = axios.CancelToken.source();
          var downloadFileName =
            this.plate.order +
            "-" +
            data.model_file_url.toString().split("plates/")[1].split("?")[0];

          axios({
            url: data.model_file_url,
            method: "GET",
            cancelToken: this.request.token,
            responseType: "blob",
            onDownloadProgress: function (progressEvent) {
              this.filePersent = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then((response) => {
              this.downloadCancelButtonTitle = this.$t("Download");
              this.downloadCancelButtonColor = "primary";
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", downloadFileName);
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              if (error.message == "Cancel") {
                this.filePersent = 0;
                this.downloadCancelButtonTitle = this.$t("Download");
                this.downloadCancelButtonColor = "primary";
              }
            });
        } else {
          this.downloadCancelButtonTitle = this.$t("Download");
          this.downloadCancelButtonColor = "primary";
          this.$vs.notify({
            title: this.$t("Warning"),
            text: this.$t("Error_Message_1"),
            color: "danger",
          });
        }
      } else if (status == 404) {
        this.downloadCancelButtonTitle = this.$t("Download");
        this.downloadCancelButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("File_Message_1"),
          color: "danger",
        });
      } else if (status == 500) {
        this.downloadCancelButtonTitle = this.$t("Download");
        this.downloadCancelButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("File_Message_1") + this.$t("Error_Message_1"),
          color: "danger",
        });
      }
    },
    controlSize(size) {
      if (size == "General") {
        return "-";
      } else {
        return size;
      }
    },
    openPopupForDetails() {
      this.plateDetail = [];

      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(
        API.BASEURL + API.PLATES + this.plate.id + "/" + this.plate.order,
        this.handleOpenPopupForDetails
      );
    },
    handleOpenPopupForDetails(status, data) {
      this.$vs.loading.close();
      if (status == 0) {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Internet_Connection_Message"),
          color: "danger",
        });
      }
      if (status == 200) {
        this.plateDetail = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popupInfo {
  font-weight: 800;
  font-size: 14px;
  margin: 2px;
}
.productImage {
  width: 75% !important;
  height: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.valueBackground {
  background-color: white;
  padding: 5px 15px;
  .p {
    font-size: 1rem;
  }
}
</style>

<style lang="scss">
.vs-pagination--mb {
  justify-content: center !important;
}
.con-vs-popup.fullscreen .vs-popup {
  width: auto !important;
  height: auto !important;
  .popupFullScreen {
    width: 100%;
    height: 100%;
    max-width: 800px;
  }
}
</style>
