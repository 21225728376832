import { API } from "@/api/config";
import axios from "axios";
import state from "@/store/state";
import store from "@/store/store";
import router from "@/router";

const Api = {
  logout() {
    var formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    return axios.request({
      method: "post",
      url: API.BASEURL + API.REVOKE_TOKEN,
      data: formData,
      timeout: 10000,
      auth: {
        username: state.client_id,
        password: state.client_secret
      }
    });
  },

  login(userData, callback) {
    var formData = new FormData();
    formData.append("username", userData.username);
    formData.append("password", userData.password);
    formData.append("grant_type", "password");

    axios
      .request({
        method: "post",
        url: API.BASEURL + API.TOKEN,
        data: formData,
        timeout: 10000,
        auth: {
          username: state.client_id,
          password: state.client_secret
        }
      })
      .then(response => {
        if (response.status == 200) {
          // TODO STORE FONKSİYONU
          let token = response.data.access_token;
          let refreshToken = response.data.refresh_token;

          store.commit("SET_TOKEN", {
            token: token,
            reflesh_token_state: refreshToken
          });
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);

          callback(200);
        }
      })
      .catch(error => {
        if (
          error.message == "timeout of 10000ms exceeded" ||
          error.message == "Network Error"
        ) {
          callback(0);
        } else if (error.response.status === 400) {
          callback(400, error.response);
        } else if (error.response.status === 500) {
          callback(500);
        }
      });
  },

  refreshTokenRequest() {
    var formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", state.refreshToken);

    if (state.refreshToken != "") {
      axios
        .request({
          method: "post",
          url: API.BASEURL + API.TOKEN,
          data: formData,
          timeout: 10000,
          auth: {
            username: state.client_id,
            password: state.client_secret
          }
        })
        .then(response => {
          var token = response.data.access_token;
          var refreshToken = response.data.refresh_token;
          store.commit("SET_TOKEN", {
            token: token,
            reflesh_token_state: refreshToken
          });
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
        })
        .catch(error => {
          if (error.response.status === 400 || error.response.status === 500) {
            router.push("/login").catch(error => {
              if (
                error.name != "NavigationDuplicated" &&
                error.name != "Error"
              ) {
                throw error;
              }
            });
          }
          store.dispatch("logout");
        });
    }
  },

  post(URL, callback, params) {
    axios
      .request({
        method: "post",
        url: URL,
        timeout: 10000,
        data: params,
        headers: {
          authorization: "Bearer " + state.token
        }
      })
      .then(response => {
        if (response.status == 200) {
          callback(200, response.data);
        } else if (response.status == 201) {
          callback(201, response.data);
        } else if (response.status == 204) {
          callback(204, response.data);
        } else {
          callback(response.status, response.data);
        }
      })
      .catch(error => {
        if (
          error.message == "timeout of 10000ms exceeded" ||
          error.message == "Network Error"
        ) {
          callback(0);
        } else if (error.response.status === 401) {
          this.refreshTokenRequest();
          setTimeout(() => {
            this.get(URL, callback, params);
            callback(401, error.response);
          }, 1000);
        } else if (error.response.status === 403) {
          callback(403, error.response);
          store.dispatch("logout");
          router.push("/login").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          callback(error.response.status, error.response);
        } else {
          router.push("/").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      });
  },

  patch(URL, callback, params) {
    axios
      .request({
        method: "patch",
        url: URL,
        timeout: 10000,
        data: params,
        headers: {
          authorization: "Bearer " + state.token
        }
      })
      .then(response => {
        if (response.status == 200) {
          callback(200, response.data);
        } else if (response.status == 201) {
          callback(201, response.data);
        }
      })
      .catch(error => {
        if (error.message == "Network Error") {
          callback(0);
        } else if (error.response.status === 401) {
          this.refreshTokenRequest();
          setTimeout(() => {
            this.get(URL, callback, params);
            callback(401);
          }, 1000);
        } else if (error.response.status === 403) {
          callback(403, error.response);
          store.dispatch("logout");
          router.push("/login").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          callback(error.response.status, error.response);
        } else {
          router.push("/").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      });
  },

  put(URL, callback, params) {
    axios
      .request({
        method: "put",
        url: URL,
        timeout: 10000,
        data: params,

        headers: {
          authorization: "Bearer " + state.token
        }
      })
      .then(response => {
        if (response.status == 200) {
          callback(200, response.data);
        } else if (response.status == 201) {
          callback(201, response.data);
        }
      })
      .catch(error => {
        if (error.message == "Network Error") {
          callback(0);
        } else if (error.response.status === 401) {
          this.refreshTokenRequest();
          setTimeout(() => {
            this.get(URL, callback, params);
            callback(401);
          }, 1000);
        } else if (error.response.status === 403) {
          callback(403);
          store.dispatch("logout");
          router.push("/login").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          callback(error.response.status, error.response);
        } else {
          router.push("/").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      });
  },

  get(URL, callback, params) {
    axios
      .request({
        method: "get",
        url: URL,
        timeout: 10000,
        headers: {
          authorization: "Bearer " + state.token
        },
        params: params
      })
      .then(response => {
        if (response.status == 200) {
          callback(200, response.data);
        }
      })
      .catch(error => {
        if (
          error.message == "timeout of 10000ms exceeded" ||
          error.message == "Network Error"
        ) {
          router.push("/maintenance").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });

          callback(0);
        } else if (error.response.status === 400) {
          callback(400, error.response.data);
        } else if (error.response.status === 401) {
          this.refreshTokenRequest();
          setTimeout(() => {
            if (state.token != "") {
              this.get(URL, callback, params);
              callback(401);
            }
          }, 1000);
        } else if (error.response.status === 403) {
          callback(403);
          store.dispatch("logout");
          router.push("/login").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        } else if (error.response.status === 500) {
          callback(500);
        } else if (error.response.status === 404) {
          callback(404, error.response);
        } else {
          router.push("/").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      });
  },
  delete(URL, callback) {
    if (state.token == "") {
      return;
    }

    axios
      .request({
        method: "delete",
        url: URL,
        timeout: 10000,
        headers: {
          authorization: "Bearer " + state.token
        }
      })
      .then(response => {
        if (response.status == 204) {
          callback(204, response.data);
        }
      })
      .catch(error => {
        if (error.message == "Network Error") {
          callback(0);
        } else if (error.message == "timeout of 10000ms exceeded") {
          callback(0);
        } else if (error.response.status === 401) {
          this.refreshTokenRequest();
          setTimeout(() => {
            this.get(URL, callback);
            callback(401);
          }, 1000);
        } else if (error.response.status === 403) {
          callback(403);
          store.dispatch("logout");
          router.push("/login").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        } else if (error.response.status === 500) {
          callback(500);
        } else if (error.response.status === 404) {
          callback(404, error.response);
        } else if (error.response.status === 400) {
          callback(400, error.response);
        } else {
          router.push("/").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      });
  },
  options(URL, callback, params) {
    axios
      .request({
        method: "options",
        url: URL,
        timeout: 10000,
        headers: {
          authorization: "Bearer " + state.token
        },
        params: params
      })
      .then(response => {
        if (response.status == 200) {
          callback(200, response.data);
        }
      })
      .catch(error => {
        if (
          error.message == "timeout of 10000ms exceeded" ||
          error.message == "Network Error"
        ) {
          router.push("/maintenance").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });

          callback(0);
        } else if (error.response.status === 400) {
          callback(400, error.response.data);
        } else if (error.response.status === 401) {
          this.refreshTokenRequest();
          setTimeout(() => {
            this.get(URL, callback, params);
            callback(401);
          }, 1000);
        } else if (error.response.status === 403) {
          callback(403);
          store.dispatch("logout");
          router.push("/login").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        } else if (error.response.status === 500) {
          callback(500);
        } else if (error.response.status === 404) {
          callback(404, error.response);
        } else {
          router.push("/").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
        }
      });
  }
};

export default Api;
