<template>
  <div>
    <div>
      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-input
          type="number"
          class="searchAndSort ml-2 pt-2 pb-2 w-full"
          icon="search"
          icon-no-border
          size="medium"
          v-model="searchValue"
          @keypress.enter="searchOrderPlate()"
        />
      </vs-row>
    </div>
    <div class="text-center p-6" v-if="plates.length == 0">
      {{ $t("Design_Message_25") }}
      <br />

      <vs-button
        class="mt-6"
        @click="
          searchValue = '';
          searchOrderPlate();
        "
      >
        {{ $t("Clean_Filters") }}
      </vs-button>
    </div>

    <div v-if="plates.length" class="vx-row">
      <div class="flex xl:w-1/2 w-full">
        <div class="tabHeader w-2/12">
          {{ $t("Order").toUpperCase() }} {{ $t("No_Number").toUpperCase() }}
        </div>
        <div class="tabHeader w-2/12">
          {{ $t("Plate_Name").toUpperCase() }}
        </div>
        <div class="tabHeader w-2/12">
          {{ $t("Remaining").toUpperCase() }}
        </div>
        <div class="tabHeader w-6/12"></div>
      </div>
      <div v-if="plates.length > 1" class="xl:flex w-1/2 xs:hidden">
        <div class="tabHeader w-2/12">
          {{ $t("Order").toUpperCase() }} {{ $t("No_Number").toUpperCase() }}
        </div>
        <div class="tabHeader w-2/12">
          {{ $t("Plate_Name").toUpperCase() }}
        </div>
        <div class="tabHeader w-2/12">
          {{ $t("Remaining").toUpperCase() }}
        </div>
        <div class="tabHeader w-6/12"></div>
      </div>
    </div>

    <div class="vx-row backgroundWhite">
      <div class="xl:w-1/2 w-full" v-for="plate in plates" :key="plate.id">
        <plate :plate="plate" />
      </div>
    </div>
    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="handleChangePage()"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import plate from "./components/Plate";

export default {
  data() {
    return {
      searchValue: null,
      searchActive: true,
      plates: [],
      //Pagination
      currentPage: 1,
      totalPage: 1,
    };
  },

  components: {
    plate,
  },

  methods: {
    searchOrderPlate() {
      if (this.searchActive) {
        this.searchActive = false;
        this.currentPage = 1;
        this.getPlates();
      }
    },
    handleChangePage() {
      this.getPlates();
    },
    getPlates() {
      var params = {};
      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }

      if (this.searchValue != null && this.searchValue != "") {
        params.search = this.searchValue;
      }

      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });

      Api.get(API.BASEURL + API.PLATES, this.handleGetPlateDetails, params);
    },

    handleGetPlateDetails(status, data) {
      this.searchActive = true;
      this.$vs.loading.close();
      if (status == 200) {
        this.totalPage = Math.ceil(data.count / 50);
        var newPlates = [];

        data.results.forEach((element) => {
          if (element.order.length == 0) {
            element.order = null;
            newPlates.push(element);
          } else if (element.order.length == 1) {
            element.order = element.order[0].id;
            newPlates.push(element);
          } else {
            element.order.forEach((newOrderElement) => {
              element.order = newOrderElement.id;
              newPlates.push(element);
            });
          }
        });

        this.plates = newPlates;
      } else if (status == 0) {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Internet_Connection_Message"),
          color: "danger",
        });
      }
    },
  },
  created() {
    if (this.$route.query.page != null && this.$route.query.page != "1") {
      this.currentPage = parseInt(this.$route.query.page);
    }
    if (this.$route.query.order != null) {
      this.searchValue = this.$route.query.order;
    }
    if (this.$route.query.search != null) {
      this.searchValue = this.$route.query.search;
    }
  },
};
</script>

<style lang="scss">
//input number'da arttır azalt butonlarını saklama
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.vs-input--icon {
  font-size: 1.5rem;
}
@media (min-width: 601px) {
  .searchAndSort {
    min-width: 220px;
    max-width: 220px;
  }
}
@media (max-width: 601px) {
  .searchAndSort {
    min-width: auto;
    max-width: auto;
  }
}
@media (max-width: 651px) {
  .searchAndSort {
    min-width: 150px;
    max-width: 200px;
  }
}
</style>
<style lang="scss" scoped>
.backgroundWhite {
  background-color: white;
}
.tabHeader {
  padding: 10px 15px;
  border: 0;
  text-align: left;
  font-size: 0.85rem;
  font-weight: 800;
  background-color: white;
}
</style>
